window.addEventListener("DOMContentLoaded", function() {
    const el = document.querySelector('.tpl-faq');
    if (el) {
        new FaqHandler(el);
    }
});

class FaqHandler {

    constructor(el) {
        el.querySelectorAll('.title').forEach(x => x.addEventListener('click', this.toggleSection));
        el.querySelectorAll('.question').forEach(x => x.addEventListener('click', this.toggleQuestion));
        const hash = document.location.hash ? document.location.hash.substr(1) : null;
        if (hash) {
            const level2El = document.querySelector(`.item-${hash}`)
            if (level2El) {
                const level1El = level2El.parentNode.closest('li');
                level1El.querySelector('.title').click();
                level2El.querySelector('.question').click();
                document.location = '#'+hash;
            }
        }
    }

    toggleSection = e => {
        const sectionEl = e.target.closest('li');
        if (sectionEl.classList.contains('selected')) {
            sectionEl.classList.remove('selected');
        } else {
            sectionEl.classList.add('selected');
        }
        window.dispatchEvent(new CustomEvent('resize'));
    }

    toggleQuestion = e => {
        const sectionEl = e.target.closest('li');
        if (sectionEl.classList.contains('selected')) {
            sectionEl.classList.remove('selected');
        } else {
            sectionEl.classList.add('selected');
        }
        window.dispatchEvent(new CustomEvent('resize'));
    }
}
