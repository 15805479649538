window.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll('img[data-full-src]').forEach(el => {
        el.style.cursor = 'pointer';
        el.addEventListener('click', function (e) {
            const root = document.createElement('div');
            root.classList.add('page-modal-full');
            root.style.display = 'block';
            const close = document.createElement('div');
            close.classList.add('close');
            close.innerHTML = '×';
            close.addEventListener('click', e => e.target.closest('.page-modal-full').remove());
            const header = document.createElement('div');
            header.classList.add('header');
            header.style.textAlign = 'center';
            header.style.padding = '0 40px';
            header.innerHTML = e.target.getAttribute('alt');
            const content = document.createElement('div');
            content.classList.add('content');
            content.style.cursor = 'pointer';
            content.style.background = 'url(' + e.target.getAttribute('data-full-src') + ') center center no-repeat';
            content.style.backgroundSize = 'contain';
            content.addEventListener('click', e => e.target.closest('.page-modal-full').remove());
            root.appendChild(close);
            root.appendChild(header);
            root.appendChild(content);
            document.body.appendChild(root);
        })
    })
});
