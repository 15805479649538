window.addEventListener("DOMContentLoaded", function() {
    const el = document.querySelector('.tpl-order');
    if (el) {
        new OrderHandler(el);
    }
});

const MODE_DELIVERY_VARIANT = 'deliveryVariant';
const MODE_CONDITION = 'condition';
const MODE_USER_INFO = 'userInfo';
const MODE_DELIVERY_TYPE = 'deliveryType';
const MODE_PAYMENT = 'payment';

const DELIVERY_VARIANT_FIRST_RECEIVE = 'firstReceive';
const DELIVERY_VARIANT_FIRST_SEND = 'firstSend';

const DELIVERY_TYPE_SDEK = 'sdek';
const DELIVERY_TYPE_RUSSIAN_POST = 'ruspost';

const FETCH_TARGET_DISCOUNT = 'discount';
const FETCH_TARGET_ORDER = 'order';

const titleMap = {
    [MODE_DELIVERY_VARIANT]: 'Вариант доставки',
    [MODE_CONDITION]: 'Условия',
    [MODE_USER_INFO]: 'Контакты',
    [MODE_DELIVERY_TYPE]: 'Доставка',
    [MODE_PAYMENT]: 'Оплата',
}

const clickEvent = new MouseEvent("click", {
    "view": window,
    "bubbles": true,
    "cancelable": false
});

const emailRegexp = /^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/;

class AbstractForm {

    recaptcha = null;

    initReCaptcha(id, callback) {
        if (!this.recaptcha) {
            setTimeout(() => {
                if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render !== 'function') {
                    this.initReCaptcha(id, callback);
                } else {
                    try {
                        //console.warn('Recaptcha id:', id)
                        const el = document.getElementById(id);
                        //console.warn('Recaptcha elem:', el)
                        const sitekey = el.getAttribute('data-key');
                        //console.warn('Recaptcha key:', sitekey)
                        this.recaptcha = grecaptcha.render(el, {
                            sitekey,
                            size: 'invisible',
                            badge: 'inline',
                            callback: callback,
                        });
                        //console.log('initReCaptcha set this.recaptcha', this.recaptcha)
                    } catch (e) {
                        console.log('initReCaptcha', e) /*possible duplicated instances*/
                    }
                }
            }, 100);
        }
    }

    removeReCaptcha() {
        //console.log('removeReCaptcha')
        grecaptcha.reset(this.recaptcha);
    }

    executeReCaptcha = () => {
        //console.log('executeReCaptcha', 'grecaptcha =', grecaptcha, 'this.recaptcha =', this.recaptcha);
        grecaptcha.execute(this.recaptcha);
    }

}

class OrderHandler extends AbstractForm {

    mode = MODE_DELIVERY_VARIANT;
    fetchTarget = null;
    conditionConfirmed = false;

    constructor(el) {
        super();
        this.titleEl = el.querySelector('.js-title');
        this.productId = parseInt(el.getAttribute('data-product-id'));

        this.stepListEl = el.querySelector('.step-list');
        this.stepListDeliveryVariantEl = this.stepListEl.querySelector('.js-step-delivery-variant');
        this.stepListConditionEl = this.stepListEl.querySelector('.js-step-condition');
        this.stepListUserInfoEl = this.stepListEl.querySelector('.js-step-user-info');
        this.stepListDeliveryTypeEl = this.stepListEl.querySelector('.js-step-delivery-type');
        this.stepListPaymentEl = this.stepListEl.querySelector('.js-step-payment');
        const stepBlocksEl = el.querySelector('.step-blocks');
        this.stepDeliveryVariantEl = stepBlocksEl.querySelector('.step-delivery-variant');
        this.stepConditionEl = stepBlocksEl.querySelector('.step-condition');
        this.stepUserInfoEl = stepBlocksEl.querySelector('.step-user-info');
        this.stepDeliveryTypeEl = stepBlocksEl.querySelector('.step-delivery-type');
        this.stepPaymentEl = stepBlocksEl.querySelector('.step-payment');

        this.summaryEl = el.querySelector('.summary');
        this.summaryPriceEl = this.summaryEl.querySelector('.js-price');
        this.summaryCollateralPriceEl = this.summaryEl.querySelector('.js-collateral-price');
        this.summaryDeliveryPriceEl = this.summaryEl.querySelector('.js-delivery-price');
        this.summaryDeliveryPriceValueEl = this.summaryEl.querySelector('.js-delivery-price-value');
        this.summaryDeliveryPriceCommentEl = this.summaryEl.querySelector('.js-delivery-price-comment');
        this.summaryTotalPriceEl = this.summaryEl.querySelector('.js-total-price');

        this.summaryMobEl = el.querySelector('.summary-mob');
        this.summaryMobPricesEl = this.summaryMobEl.querySelector('.js-prices');
        this.summaryMobCollateralPriceEl = this.summaryMobEl.querySelector('.js-collateral-price');
        this.summaryMobDeliveryPriceEl = this.summaryMobEl.querySelector('.js-delivery-price');
        this.summaryMobDeliveryPriceValueEl = this.summaryMobEl.querySelector('.js-delivery-price-value');
        this.summaryMobDeliveryPriceCommentEl = this.summaryMobEl.querySelector('.js-delivery-price-comment');
        this.summaryMobTotalPriceEl = this.summaryMobEl.querySelector('.js-total-price');
        this.summaryMobTotalRowEl = this.summaryMobEl.querySelector('.js-total-row');

        this.discountLabelEl = this.summaryEl.querySelector('.js-discount-label');
        this.discountBlockFormElList = [this.summaryEl.querySelector('.js-discount-block-form'), this.summaryMobEl.querySelector('.js-discount-block-form')];
        this.discountInputElList = [this.summaryEl.querySelector('.js-discount-input'), this.summaryMobEl.querySelector('.js-discount-input')];
        this.discountBtnSubmitElList = [this.summaryEl.querySelector('.js-discount-btn-submit'), this.summaryMobEl.querySelector('.js-discount-btn-submit')];
        this.discountBlockErrorElList = [this.summaryEl.querySelector('.js-discount-block-error'), this.summaryMobEl.querySelector('.js-discount-block-error')];
        this.discountErrorElList = [this.summaryEl.querySelector('.js-discount-error'), this.summaryMobEl.querySelector('.js-discount-error')];
        this.discountBlockResultElList = [this.summaryEl.querySelector('.js-discount-block-result'), this.summaryMobEl.querySelector('.js-discount-block-result')];
        this.discountCodeElList = [this.summaryEl.querySelector('.js-discount-code'), this.summaryMobEl.querySelector('.js-discount-code')];
        this.discountBtnClearElList = [...this.summaryEl.querySelectorAll('.js-discount-btn-clear'), ...this.summaryMobEl.querySelectorAll('.js-discount-btn-clear')];
        this.discountPercentEl = this.summaryEl.querySelector('.js-discount-percent');
        this.discountRubElList = [this.summaryEl.querySelector('.js-discount-rub'), this.summaryMobEl.querySelector('.js-discount-rub')];

        this.resultEl = el.querySelector('.js-order-result');
        this.resultDeliveryVariantEl = this.resultEl.querySelector('.js-delivery-variant');
        this.resultUserInfoEl = this.resultEl.querySelector('.js-user-info');
        this.resultDeliveryTypeEl = this.resultEl.querySelector('.js-delivery-type');

        this.confirmModalEl = document.querySelector('.js-modal-confirm');

        this.inputConditionConfirmEl = this.stepConditionEl.querySelector('#inputConditionConfirm');
        this.inputPhoneEl = this.stepUserInfoEl.querySelector('#inputPhone');
        this.inputEmailEl = this.stepUserInfoEl.querySelector('#inputEmail');
        this.inputRuspostFirstNameEl = this.stepDeliveryTypeEl.querySelector('#inputRuspostFirstName');
        this.inputRuspostLastNameEl = this.stepDeliveryTypeEl.querySelector('#inputRuspostLastName');
        this.inputRuspostAddressEl = this.stepDeliveryTypeEl.querySelector('#inputRuspostAddress');

        this.sdekSelectBtnEl = this.stepDeliveryTypeEl.querySelector('.js-sdek-btn');
        this.sdekSelectModalEl = this.stepDeliveryTypeEl.querySelector('.js-sdek-select-modal');
        this.sdekSelectedEl = this.stepDeliveryTypeEl.querySelector('.js-sdek-selected');
        this.sdekVariantdEl = this.stepDeliveryTypeEl.querySelector('.js-delivery-type-sdek');

        this.ruspostAddressEl = this.stepDeliveryTypeEl.querySelector('.js-ruspost-address');

        this.stepListMap = {
            [MODE_DELIVERY_VARIANT]: {el: this.stepListDeliveryVariantEl, enabled: true},
            [MODE_CONDITION]: {el: this.stepListConditionEl, enabled: false},
            [MODE_USER_INFO]: {el: this.stepListUserInfoEl, enabled: false},
            [MODE_DELIVERY_TYPE]: {el: this.stepListDeliveryTypeEl, enabled: false},
            [MODE_PAYMENT]: {el: this.stepListPaymentEl, enabled: false},
        }

        // list
        this.stepListDeliveryVariantEl.addEventListener('click', () => this.moveToMode(MODE_DELIVERY_VARIANT));
        this.stepListConditionEl.addEventListener('click', () => this.moveToMode(MODE_CONDITION));
        this.stepListUserInfoEl.addEventListener('click', () => this.moveToMode(MODE_USER_INFO));
        this.stepListDeliveryTypeEl.addEventListener('click', () => this.moveToMode(MODE_DELIVERY_TYPE));
        this.stepListPaymentEl.addEventListener('click', () => this.moveToMode(MODE_PAYMENT));

        // result
        this.resultDeliveryVariantEl.querySelector('.js-change-btn').addEventListener('click', () => this.moveToMode(MODE_DELIVERY_VARIANT));
        this.resultUserInfoEl.querySelector('.js-change-btn').addEventListener('click', () => this.moveToMode(MODE_USER_INFO));
        this.resultDeliveryTypeEl.querySelector('.js-change-btn').addEventListener('click', () => this.moveToMode(MODE_DELIVERY_TYPE));

        // summary
        this.summaryMobTotalRowEl.addEventListener('click', () => {
            if (this.summaryMobPricesEl.classList.contains('expanded')) {
                this.summaryMobPricesEl.classList.remove('expanded');
            } else {
                this.summaryMobPricesEl.classList.add('expanded');
            }
        })

        // discount
        //this.discountInputElList.forEach(el => el.addEventListener('keydown', this.onDiscountKeyDown));
        //this.discountInputElList.forEach(el => el.addEventListener('input', this.onDiscountKeyDown));
        this.discountInputElList.forEach(el => el.addEventListener('keyup', this.onDiscountKeyUp));
        this.discountBtnSubmitElList.forEach(el => el.addEventListener('click', this.onDiscountSubmit));
        this.discountBtnClearElList.forEach(el => el.addEventListener('click', this.onDiscountClear));

        // delivery variant
        this.stepDeliveryVariantEl.querySelectorAll('.js-delivery-variant')
            .forEach(p => p.addEventListener('click', this.onUpdateDeliveryVariant));

        // condition
        this.inputConditionConfirmEl.addEventListener('click', () => this.hideValidateError(this.inputConditionConfirmEl));

        // user info
        this.inputPhoneEl.addEventListener('keyup', this.onUpdateUserPhone);
        this.inputEmailEl.addEventListener('keyup', this.onUpdateUserEmail);
        new PhoneFormatter(this.inputPhoneEl)

        // delivery type
        this.stepDeliveryTypeEl.querySelectorAll('.js-delivery-type')
            .forEach(p => p.addEventListener('click', this.onUpdateDeliveryType));
        this.sdekSelectBtnEl.addEventListener('click', () => {this.sdekWidget(); this.sdekSelectModalEl.style.display = 'block';});
        this.inputRuspostFirstNameEl.addEventListener('keyup', this.onUpdateRuspostFirstName);
        this.inputRuspostLastNameEl.addEventListener('keyup', this.onUpdateRuspostLastName);
        this.inputRuspostAddressEl.addEventListener('keyup', this.onUpdateRuspostAddress);

        this.checkRuspostAddressDebounceTimeout = null;

        // payment
        this.paymentErrorEl = el.querySelector('.js-payment-error');
        this.paymentAgreementEl = this.stepPaymentEl.querySelector('#inputAgreementConfirm');
        this.paymentBtnEl = this.stepPaymentEl.querySelector('.next-btn');

        // next
        this.stepDeliveryVariantEl.querySelector('.next-btn').addEventListener('click', () => this.setMode(MODE_CONDITION));
        this.stepConditionEl.querySelector('.next-btn').addEventListener('click', () =>
            this.conditionConfirmed ? this.setMode(MODE_USER_INFO) : this.confirmModalEl.style.display = 'block'
        );
        this.confirmModalEl.querySelector('.js-button').addEventListener('click', () => {
            this.hideValidateError(this.inputConditionConfirmEl);
            if (this.validateConditionConfirm()) {
                this.confirmModalEl.style.display = 'none';
                this.conditionConfirmed = true;
                this.setMode(MODE_USER_INFO);
            }
        })
        this.stepUserInfoEl.querySelector('.next-btn').addEventListener('click', () => {
            this.hideValidateError(this.inputPhoneEl, this.inputEmailEl);
            if (this.validateUserInfo()) this.setMode(MODE_DELIVERY_TYPE);
        });
        this.stepDeliveryTypeEl.querySelector('.next-btn').addEventListener('click', () => {
            this.hideValidateError(
                this.sdekVariantdEl,
                this.inputRuspostFirstNameEl,
                this.inputRuspostLastNameEl,
                this.inputRuspostAddressEl,
            );
            if (this.validateDeliveryType()) this.setMode(MODE_PAYMENT);
        });
        this.paymentBtnEl.addEventListener('click', this.validatePayment);

        // prev
        this.stepConditionEl.querySelector('.prev-btn').addEventListener('click', () => this.setMode(MODE_DELIVERY_VARIANT));
        this.stepUserInfoEl.querySelector('.prev-btn').addEventListener('click', () => this.setMode(MODE_CONDITION));
        this.stepDeliveryTypeEl.querySelector('.prev-btn').addEventListener('click', () => this.setMode(MODE_USER_INFO));
        this.stepPaymentEl.querySelector('.prev-btn').addEventListener('click', () => this.setMode(MODE_DELIVERY_TYPE));

        this.stepDeliveryVariantEl.querySelector('.js-delivery-variant').dispatchEvent(clickEvent);
        this.stepDeliveryTypeEl.querySelector('.js-delivery-type').dispatchEvent(clickEvent);
        this.updateTotalPrice();
        this.initReCaptcha('recaptchaOrder', this.fetchRouter);
    }

    setMode = (mode) => {
        if (mode === this.mode) {
            return;
        }

        this.stepDeliveryVariantEl.style.display = mode === MODE_DELIVERY_VARIANT ? 'block' : 'none';
        this.stepConditionEl.style.display = mode === MODE_CONDITION ? 'block' : 'none';
        this.stepUserInfoEl.style.display = mode === MODE_USER_INFO ? 'block' : 'none';
        this.stepDeliveryTypeEl.style.display = mode === MODE_DELIVERY_TYPE ? 'block' : 'none';
        this.stepPaymentEl.style.display = mode === MODE_PAYMENT ? 'block' : 'none';

        switch (mode) {
            case MODE_DELIVERY_VARIANT:
                this.resultEl.style.display = 'none';
                break;
            case MODE_CONDITION:
            case MODE_USER_INFO:
                this.resultEl.style.display = 'block';
                this.resultUserInfoEl.style.display = 'none';
                this.resultDeliveryTypeEl.style.display = 'none';
                break;
            case MODE_DELIVERY_TYPE:
                this.resultEl.style.display = 'block';
                this.resultUserInfoEl.style.display = 'block';
                this.resultDeliveryTypeEl.style.display = 'none';
                break;
            case MODE_PAYMENT:
                this.resultEl.style.display = 'block';
                this.resultUserInfoEl.style.display = 'block';
                this.resultDeliveryTypeEl.style.display = 'block';
                break;
        }

        this.titleEl.innerText = titleMap[mode];
        this.stepListEl.querySelector('.active').classList.remove('active');
        this.stepListMap[mode].el.classList.add('active');

        const prevEnabled = this.stepListMap[mode].enabled;
        this.stepListMap[mode].enabled = true;
        if (mode === MODE_DELIVERY_TYPE && !prevEnabled) {
            this.updateDeliveryTypePrice(); // при открытии в первый раз, чтоб показало строку с суммой в summary
        }
        if (mode !== MODE_DELIVERY_VARIANT && !prevEnabled) {
            yandexGoal('checkout_' + mode);
        }
        this.updateListEnabled();

        this.mode = mode;
        document.location = '#top';
    }

    moveToMode = mode => {
        if (this.stepListMap[mode].enabled) {
            this.setMode(mode);
        }
    }

    // discount

    onDiscountKeyDown = e => {
        if (e.key > 'a' && e.key < 'z') {
            e.preventDefault();
            e.target.value += e.key.toUpperCase();
        }
    }
    onDiscountKeyUp = e => this.discountInputElList.forEach(el => {if (el !== e.target) {el.value = e.target.value;}});

    onDiscountSubmit = () => {
        const code = this.discountInputElList[0].value;
        if (!code) {
            return;
        }
        this.fetchTarget = FETCH_TARGET_DISCOUNT;
        this.executeReCaptcha();
    }

    discountSubmit = token => {
        const code = this.discountInputElList[0].value;
        fetch('/order/promo/'+code+'?'+ new URLSearchParams({
            token,
            productId: this.summaryPriceEl.getAttribute('data-product-id'),
            total: this.summaryPriceEl.getAttribute('data-price'),
        }), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(resp => resp.json())
            .then(json => {
                if (json.error) {
                    this.discountErrorElList.forEach(el => el.innerHTML = json.error);
                    this.discountBlockFormElList.forEach(el => el.style.display = 'none');
                    this.discountBlockErrorElList.forEach(el => el.style.display = 'flex');
                    return;
                }
                this.discountRubElList.forEach(el => {
                    el.innerHTML = '&ndash; ' + this.formatPrice(json.rub);
                    el.setAttribute('data-discount', json.rub);
                });
                this.discountPercentEl.innerHTML = '&ndash; ' + json.percent + '%';
                this.discountInputElList.forEach(el => el.value = '');
                this.discountCodeElList.forEach(el => el.innerHTML = code);
                this.discountLabelEl.innerHTML = 'Использован промо-код';
                this.discountBlockFormElList.forEach(el => el.style.display = 'none');
                this.discountBlockResultElList.forEach(el => el.style.display = 'flex');
                this.updateTotalPrice();
            });
    }

    onDiscountClear = () => {
        this.discountRubElList.forEach(el => el.innerHTML = '');
        this.discountRubElList.forEach(el => el.removeAttribute('data-discount'));
        this.discountPercentEl.innerHTML = '';
        this.discountLabelEl.innerHTML = 'Я знаю промо-код';
        this.discountBlockResultElList.forEach(el => el.style.display = 'none');
        this.discountBlockErrorElList.forEach(el => el.style.display = 'none');
        this.discountBlockFormElList.forEach(el => el.style.display = 'flex');
        this.updateTotalPrice();
    }

    // step delivery variant

    onUpdateDeliveryVariant = e => {
        if (e.target.classList.contains('active')) {
            return;
        }
        const prevVariantEl = this.stepDeliveryVariantEl.querySelector('.js-delivery-variant.active');
        if (prevVariantEl) {
            prevVariantEl.classList.remove('active');
        }
        const variantEl = e.target.closest('.js-delivery-variant');
        variantEl.classList.add('active');
        variantEl.querySelector('input[type=radio]').checked = true;
        this.updateResultDeliveryVariant();
        this.updateDeliveryVariantPrice();
        this.updateDeliveryVariantConditionContent();
    }
    getDeliveryVariant = () => this.stepDeliveryVariantEl.querySelector('.js-delivery-variant.active').getAttribute('data-value');

    updateResultDeliveryVariant = () => {
        const variantEl = this.stepDeliveryVariantEl.querySelector('.js-delivery-variant.active');
        this.resultDeliveryVariantEl.querySelector('.js-text').innerHTML = variantEl.getAttribute('data-result');
    }

    updateDeliveryVariantPrice = () => {
        const display = this.getDeliveryVariant() === DELIVERY_VARIANT_FIRST_RECEIVE ? 'flex' : 'none';
        this.summaryCollateralPriceEl.style.display = display;
        this.summaryMobCollateralPriceEl.style.display = display;
        this.updateTotalPrice();
    }

    updateDeliveryVariantConditionContent = () => {
        const value = this.getDeliveryVariant();
        this.stepConditionEl.querySelectorAll('.js-delivery-variant-content').forEach(el => el.style.display = 'none');
        this.stepConditionEl.querySelectorAll('.js-delivery-variant-modal').forEach(el => el.style.display = 'none');
        this.stepConditionEl.querySelector(`*[data-value=${value}].js-delivery-variant-content`).style.display = 'block';
        this.stepConditionEl.querySelector(`*[data-value=${value}].js-delivery-variant-modal`).style.display = 'inline';
    }

    // step condition

    validateConditionConfirm = () => {
        if (!this.inputConditionConfirmEl.checked) {
            this.showValidateError(this.inputConditionConfirmEl);
            return false;
        }
        return true;
    }

    // step user info

    onUpdateUserEmail = () => {
        this.hideValidateError(this.inputEmailEl);
        this.updateResultUserInfo();
    }
    getUserEmail = () => this.inputEmailEl.value;

    onUpdateUserPhone = () => {
        this.hideValidateError(this.inputPhoneEl);
        this.updateResultUserInfo();
    }
    getUserPhone = () => this.inputPhoneEl.value;

    updateResultUserInfo = () => {
        this.resultUserInfoEl.querySelector('.js-text').innerHTML = `<div>${this.getUserEmail()}</div><div>+7 ${this.getUserPhone()}</div>`
    }

    validateUserInfo = () => {
        let ok = true;
        const phone = this.inputPhoneEl.value;
        if (!phone) {
            this.showValidateError(this.inputPhoneEl, 'Необходимо ввести телефон.');
            ok = false;
        } else if (phone.match(/\d/g).length < 10) {
            this.showValidateError(this.inputPhoneEl, 'Необходимо ввести телефон полностью.');
            ok = false;
        }
        const email = this.inputEmailEl.value
        if (!email) {
            this.showValidateError(this.inputEmailEl, 'Необходимо ввести email.');
            ok = false;
        } else if (!emailRegexp.test(email)) {
            this.showValidateError(this.inputEmailEl, 'Необходимо ввести корректный email.');
            ok = false;
        }
        return ok;
    }

    // step delivery type

    onUpdateDeliveryType = e => {
        if (e.target.classList.contains('active')) {
            return;
        }
        const prevVariantEl = this.stepDeliveryTypeEl.querySelector('.js-delivery-type.active');
        if (prevVariantEl) {
            prevVariantEl.querySelector('.body').style.display = 'none';
            prevVariantEl.classList.remove('active');
        }
        const variantEl = e.target.closest('.js-delivery-type');
        variantEl.querySelector('.body').style.display = 'block';
        variantEl.classList.add('active');
        variantEl.querySelector('input[type=radio]').checked = true;
        this.updateDeliveryTypePrice();
        this.updateResultDeliveryType();
    }
    getDeliveryType = () => this.stepDeliveryTypeEl.querySelector('.js-delivery-type.active').getAttribute('data-value');

    sdekWidget = () => {
        new ISDEKWidjet ({
            defaultCity: 'Москва', //какой город отображается по умолчанию
            cityFrom: 'Москва', // из какого города будет идти доставка
            country: 'Россия', // можно выбрать страну, для которой отображать список ПВЗ
            link: 'sdekPvz', // id элемента страницы, в который будет вписан виджет
            path: 'https://widget.cdek.ru/widget/scripts/', //директория с библиотеками
            servicepath: '/SDEKService.php', //ссылка на файл service.php на вашем сайте
            onChoose: this.onUpdateDeliveryTypeSdek,
        });
    }
    onUpdateDeliveryTypeSdek = data => {
        this.hideValidateError(this.sdekVariantdEl);
        const selected = {
            id: data.id,
            city: data.city,
            cityName: data.cityName,
            price: data.price,
            currency: data.currency,
            tarif: data.tarif,
            term: data.term,
            address: data.PVZ.Address,
            workTime: data.PVZ.WorkTime,
            note: data.PVZ.Note.replace(/,([0-9])/g, ', $1'),
        };
        this.sdekSelectedEl.setAttribute('data-selected', JSON.stringify(selected));
        this.sdekSelectBtnEl.innerHTML = 'Выбрать другой пункт выдачи';
        this.sdekSelectedEl.querySelector('.id').innerHTML = selected.id;
        this.sdekSelectedEl.querySelector('.address').innerHTML = selected.address;
        this.sdekSelectedEl.querySelector('.workTime').innerHTML = selected.workTime;
        this.sdekSelectedEl.querySelector('.note').innerHTML = selected.note;
        this.sdekSelectedEl.style.display = 'block';
        this.sdekSelectModalEl.style.display = 'none';
        this.updateResultDeliveryType();
    }
    getDeliveryTypeSdek = () => {
        const data = this.sdekSelectedEl.getAttribute('data-selected');
        return data ? JSON.parse(data) : null;
    }

    onUpdateRuspostFirstName = () => {
        this.hideValidateError(this.inputRuspostFirstNameEl);
        this.updateResultDeliveryType();
    }
    getRuspostFirstName = () => this.inputRuspostFirstNameEl.value;

    onUpdateRuspostLastName = () => {
        this.hideValidateError(this.inputRuspostLastNameEl);
        this.updateResultDeliveryType();
    }
    getRuspostLastName = () => this.inputRuspostLastNameEl.value;

    onUpdateRuspostAddress = e => {
        this.hideValidateError(this.inputRuspostAddressEl);
        this.checkRuspostAddressDebounce(e.target.value);
    }
    checkRuspostAddressDebounce = (address) => {
        if (null !== this.checkRuspostAddressDebounceTimeout) {
            clearTimeout(this.checkRuspostAddressDebounceTimeout);
        }
        this.checkRuspostAddressDebounceTimeout = setTimeout(this.checkRuspostAddress(address), 500);
    }
    checkRuspostAddress = (address) => () => {
        this.checkRuspostAddressDebounceTimeout = null;
        this.ruspostAddressEl.classList.remove('fail');
        this.ruspostAddressEl.classList.remove('success');
        this.ruspostAddressEl.innerHTML = 'Проверка...';
        if (!address) {
            this.ruspostAddressEl.innerHTML = 'Здесь увидите как Почта России нашла ваш адрес';
            return;
        }
        fetch('/order/ruspost/address', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({address}),
        })
            .then(resp => resp.json())
            .then(json => {
                if (json) {
                    this.ruspostAddressEl.classList.add('success');
                    this.ruspostAddressEl.innerHTML = json;
                    this.ruspostAddressEl.setAttribute('data-ok', '1');
                } else {
                    this.ruspostAddressEl.classList.add('fail');
                    this.ruspostAddressEl.innerHTML = 'Допишите адрес, пока не понимает';
                    this.ruspostAddressEl.setAttribute('data-ok', '0');
                }
                this.updateResultDeliveryType();
            })
    }
    getRuspostOriginalAddress = () => this.inputRuspostAddressEl.value;
    getRuspostAddress = () => this.ruspostAddressEl.innerHTML;
    getRuspostAddressOk = () => this.ruspostAddressEl.getAttribute('data-ok') === '1';

    updateResultDeliveryType = () => {
        const variant = this.stepDeliveryTypeEl.querySelector('.js-delivery-type.active').getAttribute('data-result');
        let info;
        if (this.getDeliveryType() === DELIVERY_TYPE_SDEK) {
            const data = this.getDeliveryTypeSdek();
            info = data ? `Пункт выдачи ${data.id}<br/>${data.address}` : '';
        } else {
            const firstName = this.getRuspostFirstName();
            const lastName = this.getRuspostLastName();
            const address = this.getRuspostAddressOk() ? this.getRuspostAddress() : null;
            info = `<div>${address}</div><div>${firstName} ${lastName}</div>`;
        }
        this.resultDeliveryTypeEl.querySelector('.js-text').innerHTML = `<div>${variant}</div><div>${info}</div>`
    }

    updateDeliveryTypePrice = () => {
        const activeEl = this.stepDeliveryTypeEl.querySelector('.js-delivery-type.active');
        const enabled = this.stepListMap[MODE_DELIVERY_TYPE].enabled
        if (activeEl && enabled) {
            this.summaryDeliveryPriceEl.setAttribute('data-price', activeEl.getAttribute('data-price'));
            this.summaryDeliveryPriceValueEl.innerHTML = this.formatPrice(activeEl.getAttribute('data-price'));
            this.summaryDeliveryPriceCommentEl.innerHTML = activeEl.getAttribute('data-comment');
            this.summaryDeliveryPriceEl.style.display = 'flex';
            this.summaryMobDeliveryPriceValueEl.innerHTML = this.formatPrice(activeEl.getAttribute('data-price'));
            this.summaryMobDeliveryPriceCommentEl.innerHTML = activeEl.getAttribute('data-comment');
            this.summaryMobDeliveryPriceEl.style.display = 'flex';
        } else {
            this.summaryDeliveryPriceEl.setAttribute('data-price', null);
            this.summaryDeliveryPriceEl.style.display = 'none';
            this.summaryMobDeliveryPriceEl.style.display = 'none';
        }
        this.updateTotalPrice();
    }

    validateDeliveryType = () => {
        let ok = true;
        const variant = this.getDeliveryType();
        if (variant === DELIVERY_TYPE_SDEK) {
            if (!this.getDeliveryTypeSdek()) {
                this.showValidateError(this.sdekVariantdEl);
                ok = false;
            }
        }
        if (variant === DELIVERY_TYPE_RUSSIAN_POST) {
            if (!this.inputRuspostFirstNameEl.value) {
                this.showValidateError(this.inputRuspostFirstNameEl);
                ok = false;
            }
            if (!this.inputRuspostLastNameEl.value) {
                this.showValidateError(this.inputRuspostLastNameEl);
                ok = false;
            }
            if (!this.getRuspostAddressOk()) {
                this.showValidateError(this.inputRuspostAddressEl);
                ok = false;
            }
        }
        return ok;
    }

    // step payment

    validatePayment = () => {
        if (this.paymentBtnEl.classList.contains('disabled')) {
            return;
        }
        if (!this.paymentAgreementEl.checked) {
            this.paymentErrorEl.innerHTML = 'Нужно согласиться с Пользовательским соглашением и Политикой обработки персональных данных';
            this.paymentErrorEl.style.display = 'block';
            return;
        }
        this.fetchTarget = FETCH_TARGET_ORDER;
        this.executeReCaptcha();
    }

    validateAndCreateOrder = token => {
        this.paymentBtnEl.classList.add('disabled');
        this.paymentErrorEl.style.display = 'none';
        const phone = this.getUserPhone()
        const deliveryType = this.getDeliveryType();
        const sdek = this.getDeliveryTypeSdek();
        const data = {
            token,
            productId: this.productId,
            price: this.getTotalPrice(),
            promo: this.discountCodeElList[0].innerHTML,
            deliveryVariant: this.getDeliveryVariant(),
            userEmail: this.getUserEmail(),
            userPhone: phone ? phone.match(/\d/g).join('') : '',
            deliveryType: deliveryType,
            deliveryTypeSdek: deliveryType === DELIVERY_TYPE_SDEK && sdek ? sdek.id : null,
            deliveryTypeRuspost: deliveryType === DELIVERY_TYPE_RUSSIAN_POST ? {
                firstName: this.getRuspostFirstName(),
                lastName: this.getRuspostLastName(),
                address: this.getRuspostOriginalAddress(),
            } : null,
        }

        fetch('/order/create', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(resp => resp.json())
            .then(json => {
                if (json.errors) {
                    const errors = Object.entries(json.errors).map(x => x[1]);
                    this.paymentErrorEl.innerHTML = errors.join('<br/>');
                    this.paymentErrorEl.style.display = 'block';
                    this.paymentBtnEl.classList.remove('disabled');
                }
                if (json.payUrl) {
                    document.location = json.payUrl;
                }
            })
            .catch(e => {
                this.paymentErrorEl.innerHTML = 'Что-то пошло не так. Сейчас мы не можем принять заказ.';
                this.paymentErrorEl.style.display = 'block';
                this.paymentBtnEl.classList.remove('disabled');
            });

    };

    // update data

    updateTotalPrice = () => {
        const productPrice = parseInt(this.summaryPriceEl.getAttribute('data-price'));
        const collateralPrice = this.summaryCollateralPriceEl.style.display === 'none'
            ? 0 : parseInt(this.summaryCollateralPriceEl.getAttribute('data-price'));
        const deliveryPrice = this.summaryDeliveryPriceEl.style.display === 'none'
            ? 0 : parseInt(this.summaryDeliveryPriceEl.getAttribute('data-price'));
        const discount = this.discountRubElList[0].getAttribute('data-discount') ? parseInt(this.discountRubElList[0].getAttribute('data-discount')) : 0;
        const totalPrice = productPrice + collateralPrice + deliveryPrice - discount;
        this.summaryTotalPriceEl.setAttribute('data-value', totalPrice);
        const formatTotalPrice = this.formatPrice(totalPrice);
        this.summaryTotalPriceEl.innerHTML = formatTotalPrice;
        this.summaryMobTotalPriceEl.innerHTML = formatTotalPrice;
    }
    getTotalPrice = () => {
        const value = this.summaryTotalPriceEl.getAttribute('data-value');
        return value ? parseInt(value) : null;
    }

    updateListEnabled = () => {
        for (const [mode, item] of Object.entries(this.stepListMap)) {
            if (item.enabled) {
                item.el.classList.add('enabled');
            } else {
                item.el.classList.remove('enabled');
            }
        }
    }

    // utils

    fetchRouter = token => {
        this.removeReCaptcha();
        switch (this.fetchTarget) {
            case FETCH_TARGET_DISCOUNT:
                this.discountSubmit(token);
                break;
            case FETCH_TARGET_ORDER:
                this.validateAndCreateOrder(token);
                break;
            default:
                console.error('Unknown fetch target');
        }
        this.fetchTarget = null;
    }

    showValidateError(el, message) {
        if (el.tagName === 'INPUT') {
            const formGroup = el.closest('.form-group');
            if (message) {
                formGroup.querySelector('.invalid-feedback').innerHTML = message;
            }
            el.classList.add('is-invalid');
            const inputGroup = formGroup.querySelector('.input-group')
            if (inputGroup) {
                inputGroup.classList.add('is-invalid');
            }
        } else {
            el.querySelector('.invalid-feedback').style.display = 'block';
        }
    }

    hideValidateError() {
        for (const el of arguments) {
            if (el.tagName === 'INPUT') {
                el.classList.remove('is-invalid')
                const inputGroup = el.closest('.form-group').querySelector('.input-group');
                if (inputGroup) {
                    inputGroup.classList.remove('is-invalid');
                }
            } else {
                el.querySelector('.invalid-feedback').style.display = 'none';
            }
        }
    }

    formatPrice(amount) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        });
        return formatter.format(amount);
    }

}

class PhoneFormatter {

    constructor(el, handlePress, handleSubmit) {
        this.el = el;
        this.handlePress = handlePress;
        this.handleSubmit = handleSubmit;
        this.el.addEventListener('keydown', this.filter);
        this.el.addEventListener('paste', this.paste);
    }

    format(target, str, pos, move, len) {
        str = str.replace(/[^0-9]/g, '');
        let retStr = str.substr(0, 3);
        let retPos = pos + move;
        if (retPos < 0) {
            retPos = 0;
        }
        if (str.length > 3) {
            retStr += ' ' + str.substr(3, 3);
            if (move === 1 && len > 0) {
                if (pos === str.length - 1) {
                    retPos = pos + 2;
                } else if (pos === 3) {
                    retPos = 5;
                }
            }
            if (move === -1 && len === 0 && pos === 5) {
                retPos = 3;
            }
            if (move === 1 && len === 0 && pos === 3) {
                retPos = 5;
            }
        }
        if (str.length > 6) {
            retStr += '-' + str.substr(6, 2);
            if (move === 1 && len > 0) {
                if (pos === str.length) {
                    retPos = pos + 3;
                } else if (pos === 7) {
                    retPos = 9;
                }
            }
            if (move === -1 && len === 0 && pos === 9) {
                retPos = 7;
            }
            if (move === 1 && len === 0 && pos === 7) {
                retPos = 9;
            }
        }
        if (str.length > 8) {
            retStr += '-' + str.substr(8, 2);
            if (move === 1 && len > 0) {
                if (pos === str.length + 1) {
                    retPos = pos + 4;
                } else if (pos === 10) {
                    retPos = 12;
                }
            }
            if (move === -1 && len === 0 && pos === 12) {
                retPos = 10;
            }
            if (move === 1 && len === 0 && pos === 10) {
                retPos = 12;
            }
        }
        this.setCaretPosition(target, retPos);
        return retStr;
    }

    paste = (e) => {
        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        e.stopPropagation();
        e.preventDefault();
        this.el.value = this.format(e.target, e.target.value + pastedData, e.target.selectionStart, pastedData.length);
    }

    filter = (e) => {
        e.stopPropagation();
        if (!(e.ctrlKey && e.key === 'v')) {
            e.preventDefault();
        }
        if (e.keyCode === 13) {
            if (this.handleSubmit) {
                this.handleSubmit();
            }
            return false;
        }
        let pos = e.target.selectionStart;
        let value = e.target.value;
        if (e.keyCode === 37) {
            this.format(e.target, value, pos, -1, 0);
            return false;
        }
        if (e.keyCode === 39) {
            this.format(e.target, value, pos, 1, 0);
            return false;
        }
        if (e.keyCode === 8) {
            this.el.value = this.format(e.target, value.substr(0, pos - 1) + value.substr(pos), pos, -1, 0);
            return false;
        }
        if (e.keyCode === 46) {
            this.el.value = this.format(e.target, value.substr(0, pos) + value.substr(pos + 1), pos, 0, 0);
            return false;
        }
        if (this.handlePress) {
            this.handlePress();
        }
        let allowKey = (e.keyCode >= 48 && e.keyCode <= 57);
        this.el.value = this.format(e.target, value.substr(0, pos) + e.key + value.substr(pos), pos, allowKey ? 1 : 0, allowKey ? 1 : 0);
        return false;
    }

    setCaretPosition(elem, pos) {
        if (elem.createTextRange) {
            const range = elem.createTextRange();
            range.move('character', pos);
            range.select();
        } else {
            if (elem.selectionStart !== undefined) {
                elem.focus();
                elem.setSelectionRange(pos, pos);
            } else {
                elem.focus();
            }
        }
    }
}
