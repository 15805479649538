import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
// import './bootstrap';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://c21d2bbb590f4727ac1ecd7c69feaf7e@o999783.ingest.sentry.io/5958980",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

import './js/metrics.js';
import './js/app.js';
import './js/order.js';
import './js/faq.js';
import './js/image.js';
